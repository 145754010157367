#footer {
    background: var(--color-dark);
    padding: 16px 0;

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        nav {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 12px;
            a {
                color: white;
            }
        }
    }
    .socials {
        display: flex;
        align-items: center;
        gap: 16px;
        img {
            filter: brightness(100);
        }

        .logo {
            margin-right: 24px;
        }
    }

    .creditos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        border-top: 1px solid white;
        p {
            color: white;
        }
        a {
            text-align: right;
            color: white;
        }

        @media(max-width: 600px) {
            flex-direction: column;
            text-align: center;
        }
    }
}