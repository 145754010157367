#header {
    position: absolute;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
    top: 0;
    left: 0;

    >.pesquisa {
        position: fixed;
        z-index: 999999999999;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        transition: 0.3s;
        top: 0;
        left: 0;

        &.active {
            opacity: 1;
            pointer-events: all;
        }

        >.form {
            width: 90vw;
            max-width: 1000px;

            form {
                >.inputs {
                    width: 100%;
                    position: relative;

                    label,
                    button {
                        position: absolute;
                    }

                    label {
                        color: white;
                        left: 0;
                        top: -15px;
                    }

                    button {
                        right: 0;
                        top: 0;
                        height: 50px;
                        background: transparent;
                        border: 0;
                    }

                    span {
                        width: 100px;
                        display: block;
                        margin: 15px auto;
                        cursor: pointer;
                        color: white;
                        opacity: 0.3;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    input {
                        width: 100%;
                        height: 50px;
                        border: 0;
                        border-bottom: 1px solid white;
                        background-color: transparent;
                        color: white;
                    }

                    img {
                        width: 24px;
                        height: 25px;
                    }
                }
            }
        }
    }

    .container {
        @media(max-width: 991px) {
            width: 100%;
        }

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            width: 100%;

            .logo {
                img {
                    width: 156px;
                    height: 50px;
                }
            }

            .responsive {
                cursor: pointer;
                display: none;

                @media(max-width: 991px) {
                    display: block;
                }
            }

            .menu {
                .responsive {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }

                nav {
                    display: flex;
                    align-items: center;
                    gap: 25px;

                    .socials {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        justify-content: space-between;

                        button {
                            background: transparent;
                            border: 0;
                            cursor: pointer;
                        }
                    }
                }

                @media(max-width: 991px) {
                    position: fixed;
                    top: 0;
                    right: -500px;
                    background: white;
                    height: 100vh;
                    width: 300px;
                    transition: .5s;
                    box-shadow: 0 0 50px rgba(0, 0, 0, .1);

                    &.active {
                        right: 0;
                    }

                    nav {
                        flex-direction: column;
                        align-items: center;
                        margin-top: 100px;
                    }
                }
            }
        }
    }
}