@import url("./Assets/bootstrap.css");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

body {
  opacity: 0;
  transition: .2s;
  background: white;
}

::-webkit-scrollbar {
  width: 12px;
  height: 0;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-primary-shade);
}

::-webkit-scrollbar-track {
  background: #eaeaea;
}

::-webkit-scrollbar-track:hover {
  background: #eaeaea;
}

::-webkit-scrollbar-track:active {
  background: #eaeaea;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.App {
  overflow: hidden;
}

.schema {
  display: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  letter-spacing: -0.025em;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
img {
  display: block;
}

a {
  text-decoration: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

b,
strong {
  font-weight: 600;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clear {
  clear: both;
}

body {
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

button,
input,
textarea {
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

button {
  &:disabled {
    opacity: 0.5 !important;
  }
}

.hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  overflow: hidden;
}

.inactive {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.suspense {
  width: 100vw;
  height: 100vh;
}

body>iframe {
  pointer-events: none !important;
}

h1#mainTitle {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

$colors: (
  primary: #FF7130,
  primary-shade: #e4510d,
  primary-tint: #FF8D59,
  primary-contrast: #FFFFFF,
  success: #08A604,
  success-shade: #057703,
  success-tint: #6BCA68,
  success-contrast: #FFFFFF,
  danger: #CC1C15,
  danger-shade: #ac0e09,
  danger-tint: #E07773,
  danger-contrast: #FFFFFF,
  warning: #faa719,
  warning-shade: #8d5900,
  warning-tint: #ffc766,
  warning-contrast: #FFFFFF,
  dark: #32393C,
  dark2: #5B6163,
  light: #ADB0B1,
  light2: #84888A,
  black: #000000,
  white: #FFFFFF
);

$tagTexts: (
  h1: (72px, 700, 70px, 36px, 700, 35px),
  h2: (36px, 700, 42px, 24px, 600, 30px),
  h3: (28px, 700, 30px, 20px, 600, 24px),
  h4: (18px, 400, 22px, 16px, 400, 20px),
  h5: (16px, 400, 20px, 12px, 400, 15px),
  p: (18px, 400, 32px, 16px, 400, 24px),
  li: (18px, 400, 32px, 16px, 400, 24px)
);

$classTexts: (
  p1: (18px, 400, 32px, 16px, 400, 24px),
  p2: (16px, 300, 24px, 14px, 300, 20px),
  p3: (12px, 400, 13px, 10px, 400, 16px)
);

$textWeights: (
  300,
  400,
  500,
  600,
  700
);

$defaultMultiplier: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10
);

@each $weight in $textWeights {
  .txt-weight-#{$weight} {
    font-weight: $weight !important;
  }
}

@each $class,
$info in $tagTexts {

  #{$class},
  .#{$class} {
    font-family: 'Ubuntu';
    font-style: normal;
    font-size: nth($info, 1);
    font-weight: nth($info, 2);
    line-height: nth($info, 3);

    @media(max-width: 991px) {
      font-size: nth($info, 4);
      font-weight: nth($info, 5);
      line-height: nth($info, 6);
    }
  }
}

@each $class,
$info in $classTexts {
  .#{$class} {
    font-family: 'Ubuntu';
    font-style: normal;
    font-size: nth($info, 1);
    font-weight: nth($info, 2);
    line-height: nth($info, 3);

    @media(max-width: 991px) {
      font-size: nth($info, 4);
      font-weight: nth($info, 5);
      line-height: nth($info, 6);
    }
  }
}

@each $name,
$color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }

  .txt-#{$name} {
    color: $color;
  }

  .txt-#{$name}__hover {
    &:hover {
      color: $color;
    }
  }

  .border-#{$name} {
    border-color: $color;
  }

  :root {
    --color-#{$name}: #{$color};
  }
}

.btn {
  padding: 10px 16px;
  font-size: 16px;
  height: 42px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
  font-weight: 500;
  cursor: pointer;
  border: 0;
  gap: 8px;

  &:hover {
    filter: brightness(0.8);
  }
}

.btn-large {
  padding: 8px 24px;
  height: 48px;
}

.no-border {
  border-color: transparent !important;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

body {
  opacity: 1 !important;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .content {
    width: 500px;
    max-width: 90%;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    text-align: center;

    h3 {
      font-size: 32px;
      font-weight: bold;
      color: var(--ion-text-color);
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      color: var(--ion-text-color);
      font-weight: 500;
      margin-bottom: 50px;
    }

    button {
      width: 100%;
      height: 44px;
      background: var(--color-primary);
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      color: var(--color-primary-contrast);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.bg-transparent {
  background: transparent;
}

a.floating-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  padding: 5px 5px 5px 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;

  img {
    width: 48px;
    height: 48px;
  }

  &::before {
    content: "fale conosco";
    position: absolute;
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -160px;
    font-size: 16px;
    top: 15px;
    background-color: #efefef;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #2c4760;
    border-radius: 5px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}